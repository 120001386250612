import { LoadingSpinner } from "../loaders/LoadingSpinner";
import { ProgressiveClientOnly } from "../ProgressiveClientOnly";

interface ButtonProps extends React.ComponentPropsWithRef<"button"> {
  children?: React.ReactNode;
  isLoading?: boolean;
  additionalClassNames?: string;
}

let Button: React.FC<ButtonProps> = ({
  children,
  isLoading,
  additionalClassNames = "",
  ...props
}) => {
  let isEnabled = props.disabled !== true;
  let isHover = isEnabled && !isLoading;
  return (
    <ProgressiveClientOnly className="animate-fade">
      <button
        className={`rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline 
  focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-link-light focus-visible:dark:outline-link-dark
  flex items-center justify-center bg-button-light dark:bg-button-dark text-white dark:text-white
${
  isHover &&
  " hover:bg-link-light hover:dark:bg-link-dark hover:dark:text-gray-light"
}
${
  !isEnabled &&
  !isLoading &&
  "bg-gray-light dark:bg-gray-dark text-text-light dark:text-text-dark cursor-not-allowed "
}
${additionalClassNames}  
`}
        {...props}
      >
        {isLoading && <LoadingSpinner />}
        {children}
      </button>
    </ProgressiveClientOnly>
  );
};

export default Button;
